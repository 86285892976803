import React, { useState } from "react";
import { graphql } from "gatsby";

import "../styles/pages/gallery.scss";
import Seo from "../components/Seo/Seo";
import Layout from "../components/Layout/Layout";
import GalleryFilters from "../components/GalleryFilters/GalleryFilters";
import GalleryGrid from "../components/GalleryGrid/GalleryGrid";
import { Container, Row, Col } from "react-bootstrap";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

function GalleryPage({ data, galleryTag, pageContext }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const tags = data.allContentfulGalleryTagSection.nodes;
  const grid = data.allContentfulProject.nodes;
  const title = "Галерея проектов";
  let [activeTag, changeTag] = useState("");
  return (
    <Layout>
      <Seo title={title} />
      <Container className="content" fluid="xl">
        <Row noGutters={true}>
          <Col>
            <Breadcrumb crumbs={crumbs} crumbLabel={title} />
            <h1 className="page--heading">{title}</h1>

            <p className="page--desc">
              {/* <p>На этой странице собраны проекты, выполненные на нашем производстве. Вы можете ознакомиться как с нашими долгосрочными проектами, такими как изготовление презентабельных лестниц, отделкой интерьеров и реставрацией памятников культурного наследия, так и с небольшими штучными изделиями. </p>
                          <p>Наша столярная мастерская выполняет широкий спектр работ по дереву. Беремся за выполнение любого деревянного изделия. Создадим для вас элементы декора, доборы и декоративный погонаж, который невозможно найти в розничных магазинах. Но также с легкостью возьмемся за выполнение крупного деревянного изделия, в том числе лестницы, мебели, интерьера и работ по реставрации.</p> */}
              <p>Для удобства навигации, выберите интересующий вас раздел.</p>
            </p>
            <div className="gallery-filters--reset">
              <button
                onClick={() => changeTag((activeTag = ""))}
                className="gallery-filters--reset-btn"
              >
                Все проекты
              </button>
            </div>
            <GalleryFilters
              tags={tags}
              activeTag={activeTag}
              changeTag={changeTag}
            />
          </Col>
        </Row>
      </Container>
      <GalleryGrid page="gallery" items={grid} activeTag={activeTag} />
    </Layout>
  );
}

export default GalleryPage;

export const galleryPageQuery = graphql`
  query galleryPageQuery {
    allContentfulGalleryTagSection(sort: { fields: order, order: ASC }) {
      nodes {
        tagSectionTitle
        tags {
          tag
          id
        }
      }
    }
    allContentfulProject(sort: { fields: number, order: DESC }) {
      nodes {
        title
        slug
        number
        tags {
          tag
        }
        heroImage {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
          title
        }
        images {
          fixed(width: 1000) {
            ...GatsbyContentfulFixed
          }
          file {
            url
          }
          title
        }
      }
    }
  }
`;
