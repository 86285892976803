import React from 'react'
import './GalleryFilters.scss'
import {Row, Col}  from 'react-bootstrap'

function GalleryFilters({tags, activeTag, changeTag}) {
    function modifyTag(tag) {
        let clearTag = tag[1];
        if (tag === '#КГИОП') {
            clearTag = 'КГИОП'
        }
        else if (tag.includes('КГИОП')) {
            const startKGIOP = tag.indexOf('КГИОП');
            for (let i = 2; i < startKGIOP; i++) {
                if (tag[i] === tag[i].toUpperCase()) {
                    clearTag = clearTag + ' ' + tag[i].toLowerCase()
                }
                else {
                    clearTag = clearTag + tag[i]
                }
            }
            clearTag = clearTag + ' КГИОП';
        }
        else {
            for (let i = 2; i < tag.length; i++) {
                if (tag[i] === tag[i].toUpperCase()) {
                    clearTag = clearTag + ' ' + tag[i].toLowerCase()
                }
                else {
                    clearTag = clearTag + tag[i]
                }
            }
        }
        return clearTag
    }

    return (
        <Row className='gallery-filters'>
        {
        tags.map((section) => {
            return (

                <Col key={section.tagSectionTitle} xs={12} md={6}>
                    <h4 className='gallery-filters--title'>{section.tagSectionTitle}</h4>
                    <ul className="gallery-filters--ul">
                        {
                                section.tags.map((item) => {
                                    return (
                                    <li key={item.id} className="gallery-filters--li">
                                        <button
                                            className={activeTag === item.tag ? 'gallery-filters--btn gallery-filters--btn_active': 'gallery-filters--btn'}
                                            onClick={() => changeTag(activeTag = item.tag)}>
                                        {modifyTag(item.tag)}
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </Col>
            )
        })
        }
        </Row>
    )
}

export default GalleryFilters

